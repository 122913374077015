import {
  createContext,
  useContext,
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Container from '../components/atoms/Container';

const ModalContext = createContext();

export const useModalContext = () => useContext(ModalContext);

export function ModalProvider({ children }) {
  const [modalState, setModalState] = useState(null); // { content, options }

  const createModal = useCallback(
    (
      content,
      options = {
        enableVerticalScroll: false,
        wider: false,
        closeOnOutsideClick: true,
      }
    ) => {
      setModalState({ content, options });
    },
    []
  );

  const removeModal = useCallback(() => {
    setModalState(null);
  }, []);

  const modalContextValue = useMemo(
    () => ({
      createModal,
      removeModal,
    }),
    [createModal, removeModal]
  );

  return (
    <ModalContext.Provider value={modalContextValue}>
      {children}
      {modalState &&
        ReactDOM.createPortal(
          <ModalWrapper options={modalState.options} removeModal={removeModal}>
            {modalState.content}
          </ModalWrapper>,
          document.getElementById('modal')
        )}
    </ModalContext.Provider>
  );
}

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function ModalWrapper({ children, options, removeModal }) {
  const targetRef = useRef(null);

  // If the user clicks outside the modal, it will be closed
  const handleClickOutside = useCallback(
    (event) => {
      if (
        options.closeOnOutsideClick &&
        targetRef.current &&
        !targetRef.current.contains(event.target)
      ) {
        removeModal();
      }
    },
    [options.closeOnOutsideClick, removeModal]
  );

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        removeModal();
      }
    },
    [removeModal]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleKeyPress);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleKeyPress);
    };
  }, [handleClickOutside, handleKeyPress]);

  const modalContentClasses = `${
    options.wider ? 'xl:w-2/3 md:w-3/4' : 'xl:w-1/3 md:w-1/2'
  } w-full max-h-full ${
    options.enableVerticalScroll ? 'overflow-y-auto' : 'overflow-y-hidden'
  }`;

  return (
    <div className="fixed z-40 top-0 left-0 w-full h-screen px-5 flex items-center justify-center bg-black bg-opacity-75 select-none">
      <div ref={targetRef} className={modalContentClasses}>
        <Container>{children}</Container>
      </div>
    </div>
  );
}

ModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  options: PropTypes.shape({
    enableVerticalScroll: PropTypes.bool,
    wider: PropTypes.bool,
    closeOnOutsideClick: PropTypes.bool,
  }).isRequired,
  removeModal: PropTypes.func.isRequired,
};

export default ModalProvider;