import { faPenToSquare, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import i18next from 'i18next';
import Skeleton from 'react-loading-skeleton';
import Button from '../atoms/Button';
import CardToggle from '../molecules/CardToggle';
import JobCreateModal from '../modals/JobCreateModal';
import JobUpdateModal from '../modals/JobUpdateModal';
import JobDeleteModal from '../modals/JobDeleteModal';
import createJob from '../../services/createJob';
import updateJob from '../../services/updateJob';
import deleteJob from '../../services/deleteJob';
import formatDate from '../../utils/formatDate';
import useToast from '../../hooks/useToast';
import useModal from '../../hooks/useModal';
import Title from '../atoms/Title';
import Banner from '../atoms/Banner';

function JobsHistory({ session, userJobs, countries, userCountry, areJobsLoading, areCountriesLoading }) {
    const queryClient = useQueryClient();
    const queryKey = [session, 'userJobs'];

    const { createToast, toastElement } = useToast();
    const { createModal, removeModal } = useModal();

    // Refs
    const titleRef = useRef();
    const companyRef = useRef();
    const selectedCountryRef = useRef();
    const selectedCurrencyRef = useRef();
    const salaryRef = useRef();
    const selectedJobTypeRef = useRef();
    const descriptionRef = useRef();
    const startDateRef = useRef();
    const endDateRef = useRef();

    const jobCreateMutation = useMutation({
        mutationFn: (job) =>
            createJob(
                job.title,
                job.type,
                job.company,
                job.country,
                job.startDate,
                job.endDate,
                job.description,
                job.currency,
                job.salary,
                session,
                i18next.language,
            ),
        onMutate: () => {
            createToast('info', i18next.t('loading'));
        },
        onError: (error) => {
            createToast('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createToast('success', i18next.t('job-created-successfully'));
            queryClient.invalidateQueries(queryKey);
        },
    });

    const jobUpdateMutation = useMutation({
        mutationFn: (job) =>
            updateJob(
                job.jobID,
                job.title,
                job.type,
                job.company,
                job.country,
                job.startDate,
                job.endDate,
                job.description,
                job.currency,
                job.salary,
                session,
                i18next.language,
            ),
        onMutate: () => {
            createToast('info', i18next.t('loading'));
        },
        onError: (error) => {
            createToast('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createToast('success', i18next.t('job-updated-successfully'));
            queryClient.invalidateQueries(queryKey);
        },
    });

    const jobDeleteMutation = useMutation({
        mutationFn: (jobID) => deleteJob(jobID, session, i18next.language),
        onMutate: () => {
            createToast('info', i18next.t('loading'));
        },
        onError: (error) => {
            createToast('error', error.messages.join(', '));
        },
        onSuccess: () => {
            createToast('success', i18next.t('job-deleted-successfully'));
            queryClient.invalidateQueries(queryKey);
        },
    });

    // Handlers
    
    /**
     * Create a new job
     */
    const handleJobCreate = () => {
        if (areCountriesLoading) {
            return;
        }

        const onAccept = () => {
            if (
                !titleRef.current.value ||
                !selectedJobTypeRef.current ||
                !companyRef.current.value ||
                !selectedCountryRef.current ||
                !startDateRef.current.value ||
                !selectedCurrencyRef.current ||
                !salaryRef.current.value
            ) {
                return;
            }
            jobCreateMutation.mutate({
                title: titleRef.current.value,
                type: selectedJobTypeRef.current,
                company: companyRef.current.value,
                country: selectedCountryRef.current,
                startDate: startDateRef.current.value,
                endDate: endDateRef?.current.value,
                description: descriptionRef?.current.value,
                currency: selectedCurrencyRef.current,
                salary: salaryRef.current.value,
            });
            removeModal();
        };

        const onCancel = () => {
            removeModal();
        };

        createModal(
            <JobCreateModal
                titleRef={titleRef}
                selectedJobTypeRef={selectedJobTypeRef}
                companyRef={companyRef}
                selectedCountryRef={selectedCountryRef}
                startDateRef={startDateRef}
                endDateRef={endDateRef}
                descriptionRef={descriptionRef}
                selectedCurrencyRef={selectedCurrencyRef}
                salaryRef={salaryRef}
                actualCountry={userCountry}
                countries={countries || {}}
                onAccept={onAccept}
                onCancel={onCancel}
            />,
            { enableVerticalScroll: true }
        );
    };

    const handleJobUpdate = (jobsInfoObj) => {
        const onAccept = () => {
            if (
                !titleRef.current.value ||
                !selectedJobTypeRef.current ||
                !companyRef.current.value ||
                !selectedCountryRef.current ||
                !startDateRef.current.value ||
                !selectedCurrencyRef.current ||
                !salaryRef.current.value
            )
                return;

            jobUpdateMutation.mutate({
                jobID: jobsInfoObj.jobID?.toString(),
                title: titleRef.current.value,
                type: selectedJobTypeRef.current,
                company: companyRef.current.value,
                country: selectedCountryRef.current,
                startDate: startDateRef.current.value,
                endDate: endDateRef?.current.value,
                description: descriptionRef?.current.value,
                currency: selectedCurrencyRef.current,
                salary: salaryRef.current.value,
            });

            removeModal();
        };
        const onCancel = () => {
            removeModal();
        };

        createModal(
            <JobUpdateModal
                titleRef={titleRef}
                selectedJobTypeRef={selectedJobTypeRef}
                companyRef={companyRef}
                selectedCountryRef={selectedCountryRef}
                startDateRef={startDateRef}
                endDateRef={endDateRef}
                descriptionRef={descriptionRef}
                selectedCurrencyRef={selectedCurrencyRef}
                salaryRef={salaryRef}
                countries={countries || {}}
                onAccept={onAccept}
                onCancel={onCancel}
                jobsInfoObj={{
                    jobID: jobsInfoObj && jobsInfoObj.jobID ? jobsInfoObj.jobID?.toString() : '',
                    title: jobsInfoObj && jobsInfoObj.title ? jobsInfoObj.title : '',
                    type: jobsInfoObj && jobsInfoObj.type ? jobsInfoObj.type : 'FULL_TIME',
                    company: jobsInfoObj && jobsInfoObj.company ? jobsInfoObj.company : '',
                    country: jobsInfoObj && jobsInfoObj.countryID ? jobsInfoObj.countryID.toString() : '0',
                    startDate: jobsInfoObj && jobsInfoObj.startDate ? jobsInfoObj.startDate : '',
                    endDate: jobsInfoObj && jobsInfoObj.endDate ? jobsInfoObj.endDate : '',
                    description: jobsInfoObj && jobsInfoObj.description ? jobsInfoObj.description : '',
                    currency: jobsInfoObj && jobsInfoObj.currency ? jobsInfoObj.currency : 'COP',
                    salary: jobsInfoObj && jobsInfoObj.salary ? jobsInfoObj.salary?.toString() : '',
                }}
            />,
            { enableVerticalScroll: true }
        );
    };

    /**
     * Delete Jobs
     */
    const handleJobDelete = (jobInfo) => {
        // Delete a job
        const onAccept = () => {
            removeModal();

            if (!jobInfo || !jobInfo.jobID) return;

            jobDeleteMutation.mutate(jobInfo.jobID);
        };

        const onCancel = () => {
            removeModal();
        };

        createModal(
            <JobDeleteModal title={jobInfo.title} company={jobInfo.company} onAccept={onAccept} onCancel={onCancel} />,
        );
    };

    return (
        <>
            <div className="w-full flex items-center">

                {toastElement}

                <div className="w-1/6 flex items-center justify-center">
                    <Button
                        circle
                        color="blue"
                        icon={faPlus}
                        title={i18next.t('create-new')}
                        onClick={handleJobCreate}
                    />
                </div>
                <div className="w-5/6">
                    <Banner color="white" borderColor="green">
                        {i18next.t('work-experience')}
                    </Banner>
                </div>
            </div>

            {userJobs != null && userJobs !== false && (
                <div className="w-full flex items-center justify-center mt-1 mb-1">
                    {areJobsLoading ? (
                        <Skeleton
                            count={2}
                            baseColor="#e4e4e7"
                            highlightColor="#f4f4f5"
                            height="100%"
                            className="mb-5 h-8"
                            containerClassName="w-full h-5"
                        />
                    ) : (
                        <div className="flex flex-col w-full gap-y-3">
                            {userJobs &&
                                userJobs.map((jobsInfoObj) => (
                                    <div key={jobsInfoObj.jobID} className="mt-1">
                                        <CardToggle
                                            size="lg"
                                            key={jobsInfoObj.jobID}
                                            title={`${jobsInfoObj?.title} ${i18next.t('at')} ${jobsInfoObj?.company}`}
                                        >
                                            <div className="flex flex-wrap">
                                                <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                    <div className="w-2/5">
                                                        <Title size="sm">
                                                            {i18next.t('job-title')}
                                                        </Title>
                                                    </div>
                                                    <div className="w-3/5">
                                                        <span>{jobsInfoObj.title}</span>
                                                    </div>
                                                </div>

                                                <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                    <div className="w-2/5">
                                                        <Title size="sm">
                                                            {i18next.t('job-company')}
                                                        </Title>
                                                    </div>
                                                    <div className="w-3/5">
                                                        <span>{jobsInfoObj.company}</span>
                                                    </div>
                                                </div>
                                                <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                    <div className="w-2/5">
                                                        <Title size="sm">
                                                            {i18next.t('job-country')}
                                                        </Title>
                                                    </div>
                                                    <div className="w-3/5">
                                                        <span>{jobsInfoObj.country}</span>
                                                    </div>
                                                </div>
                                                <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                    <div className="w-2/5">
                                                        <Title size="sm">
                                                            {i18next.t('job-salary')}
                                                        </Title>
                                                    </div>
                                                    <div className="w-3/5 flex-wrap">
                                                        <span>{jobsInfoObj.salary}</span>&nbsp;
                                                        <span>{jobsInfoObj.currency}</span>
                                                    </div>
                                                </div>
                                                <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                    <div className="w-2/5">
                                                        <Title size="sm">
                                                            {i18next.t('job-type')}
                                                        </Title>
                                                    </div>
                                                    <div className="w-3/5">
                                                        {jobsInfoObj.type === 'FULL_TIME' && (
                                                            <span>
                                                                {i18next.t('type-full-time')}
                                                            </span>
                                                        )}
                                                        {jobsInfoObj.type === 'PART_TIME' && (
                                                            <span>
                                                                {i18next.t('type-part-time')}
                                                            </span>
                                                        )}
                                                        {jobsInfoObj.type === 'FREELANCE' && (
                                                            <span>
                                                                {i18next.t('type-freelance')}
                                                            </span>
                                                        )}
                                                        {jobsInfoObj.type === 'INTERNSHIP' && (
                                                            <span>
                                                                {i18next.t('type-internship')}
                                                            </span>
                                                        )}
                                                        {jobsInfoObj.type ===
                                                            'APPRENTICESHIP' && (
                                                            <span>
                                                                {i18next.t(
                                                                    'type-apprenticeship',
                                                                )}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                    <div className="w-2/5">
                                                        <Title size="sm">
                                                            {i18next.t('start-date')}
                                                        </Title>
                                                    </div>
                                                    <div className="w-3/5">
                                                        <span>
                                                            {formatDate(
                                                                jobsInfoObj.startDate,
                                                                true,
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                {jobsInfoObj.endDate != null && (
                                                    <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                        <div className="w-2/5">
                                                            <Title size="sm">
                                                                {i18next.t('end-date')}
                                                            </Title>
                                                        </div>
                                                        <div className="w-3/5">
                                                            <span>
                                                                {jobsInfoObj.endDate !== false
                                                                    ? formatDate(
                                                                            jobsInfoObj.endDate,
                                                                            true,
                                                                        )
                                                                    : i18next.t('present')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                                {jobsInfoObj.description != null &&
                                                    jobsInfoObj.description !== false && (
                                                        <div className="flex items-center flex-wrap w-full border-y-2 py-2 pl-6">
                                                            <div className="w-2/5">
                                                                <Title size="sm">
                                                                    {i18next.t('description')}
                                                                </Title>
                                                            </div>
                                                            <div className="w-3/5">
                                                                <span>
                                                                    {jobsInfoObj.description}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="flex justify-between w-full flex-wrap gap-y-2 p-5">
                                                <div className="flex w-full lg:w-2/5">
                                                    <Button
                                                        color="blue"
                                                        icon={faPenToSquare}
                                                        onClick={() =>
                                                            handleJobUpdate(jobsInfoObj)
                                                        }
                                                    >
                                                        {i18next.t('edit')}
                                                    </Button>
                                                </div>
                                                <div className="flex w-full lg:w-2/5">
                                                    <Button
                                                        color="red"
                                                        icon={faTrash}
                                                        onClick={() =>
                                                            handleJobDelete({
                                                                jobID: jobsInfoObj.jobID,
                                                                title: jobsInfoObj.title,
                                                                company: jobsInfoObj.company,
                                                            })
                                                        }
                                                    >
                                                        {i18next.t('delete')}
                                                    </Button>
                                                </div>
                                            </div>
                                        </CardToggle>
                                    </div>
                                ))
                            }
                        </div>
                    )}
                </div>  
            )}
        </>
    );
}

JobsHistory.propTypes = {
    session: PropTypes.string.isRequired,
    userJobs: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(
            PropTypes.shape({
                jobID: PropTypes.number.isRequired,
                title: PropTypes.string.isRequired,
                company: PropTypes.string.isRequired,
                country: PropTypes.string.isRequired,
                currency: PropTypes.string.isRequired,
                salary: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                type: PropTypes.oneOf([
                    'FULL_TIME',
                    'PART_TIME',
                    'FREELANCE',
                    'INTERNSHIP',
                    'APPRENTICESHIP',
                ]).isRequired,
                startDate: PropTypes.string.isRequired,
                endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
                description: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
            })
        ),
    ]).isRequired,
    countries: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                code: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            })
        ),
        PropTypes.shape({}),
    ]).isRequired,
    userCountry: PropTypes.string.isRequired,
    areJobsLoading: PropTypes.bool.isRequired,
    areCountriesLoading: PropTypes.bool.isRequired,
};


export default JobsHistory;