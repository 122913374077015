import { faBan, faPlay } from '@fortawesome/free-solid-svg-icons';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';
import Countdown from '../atoms/Countdown';

/**
 * StartCompetition modal allows you to enter a competition warning of your limited time
 * @param {{
 *      time: number;
 *      onAccept: () => void;
 *      onCancel: () => void;
 * }} props
 */
function StartCompetition({ time, onAccept, onCancel }) {
    return (
        <div className="w-full p-10 flex flex-col gap-6 items-center">
            <Countdown size="md" time={time} />
            <div className="text-justify">
                <p className="font-bold">{i18next.t('important')}</p>
                {i18next.t('one-attempt-reminder')}
            </div>
            <p className="font-bold text-justify text-smorange-dark">{i18next.t('warning')}</p>
            <p className="text-justify">{i18next.t('finish-competition-button-location')}</p>
            <p className="text-justify">{i18next.t('you-are-about-to-start')}</p>
            <p className="font-bold text-lg">{i18next.t('good-luck')}</p>
            <div className="w-full flex gap-5">
                <Button color="orange" icon={faBan} onClick={onCancel}>
                    {i18next.t('cancel')}
                </Button>
                <Button color="white" icon={faPlay} onClick={onAccept}>
                    {i18next.t('start')}
                </Button>
            </div>
        </div>
    );
}

StartCompetition.propTypes = {
    time: PropTypes.number.isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default StartCompetition;
