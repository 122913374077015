import {  faArrowLeftLong, faBuilding, faPlay } from '@fortawesome/free-solid-svg-icons';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import useFeedback from '../../hooks/useFeedback';
import useCountriesQuery from '../../hooks/useCountriesQuery';
// import useRecaptcha from '../../hooks/useRecaptcha'; // ToDo: falta esto
import Button from '../atoms/Button';
import Container from '../atoms/Container';
import Input from '../atoms/Input';
import Select from '../atoms/Select';
import Title from '../atoms/Title';
import FileUploader from '../molecules/FileUploader';
import registerCompanyInnovate from '../../services/registerCompanyInnovate';
import useSession from '../../hooks/useSession';
import NavbarTemplate from '../templates/NavbarTemplate';
import validateCompanyDetails from '../../services/validateCompanyDetails';
import uploadFiles from '../../services/uploadFiles';

// eslint-disable-next-line arrow-body-style
const validateSingleSpace = (value) => {
    // It returns true if it does not have more than one consecutive space
    return !/\s{2,}/.test(value);
};

const NUMERIC_FIELDS = ['incomePrevious', 'incomeLast', 'cagr', 'clients', 'fullTeam', 'workTeam'];

/**
 * Company Register Innovate organism creates a form to add innovative details to the company registration
 */
function CompanyRegisterInnovate() {
    const { session } = useSession();
    const navigate = useNavigate();

    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();
    // const recaptcha = useRecaptcha({ mode: 'register' });

    const feedbackRef = useRef(null);

    // Fetch countries from backend
    const { data: countries, isCountryListLoading } = useCountriesQuery();

    const [showResults, setShowResults] = useState(false);
    const [isLoadingValidation, setIsLoadingValidation] = useState(true);

    const [formData, setFormData] = useState({
        formalizedCountry: '',
        modelDescription: '',
        product: '',
        difference: '',
        creator: null,
        incomePrevious: null,
        incomeLast: null,
        level: '',
        cagr: null,
        cac: '',
        ltv: '',
        churnRate: '',
        clients: null,
        time: '',
        profit: '',
        founder: '',
        fullTeam: null,
        workTeam: null,
        commercialRegistry: null,
        representativeID: null,
        patent: null,
        teamInfo: null,
    });

    const [files, setFiles] = useState({
        commercialRegistry: null,
        representativeID: null,
        teamInfo: null,
        patent: null,
    });

    useEffect(() => {
        const validateFormCompletion = async () => {
            try {
                const isCompleted = await validateCompanyDetails(session, i18next.language);
                if (isCompleted) {
                    setShowResults(true);
                }
            } catch (error) {
                createFeedback('error', i18next.t('validation-failed', 'Error al validar los datos.'));
            } finally {
                setIsLoadingValidation(false)
            }
        };

        validateFormCompletion();
    }, [session, createFeedback]);

    const handleBack = () => {
        navigate(-1);
    };

    const scrollToFeedback = () => {
        if (feedbackRef.current) {
            feedbackRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    const handleChange = useCallback((field) => (value) => {
        let newValue = value;
    
        if (['modelDescription', 'product', 'difference', 'founder', 'time', 'profit'].includes(field)) {
            // Remove extra spaces from the value
            if (validateSingleSpace(newValue)) {
                setFormData(prev => ({
                    ...prev,
                    [field]: newValue
                }));
            }
        } else if (['formalizedCountry'].includes(field)) {
            setFormData(prev => ({
                ...prev,
                [field]: value
            }));
        } else if (['creator'].includes(field)) {
            // Assuming creator is a boolean
            setFormData(prev => ({
                ...prev,
                [field]: value === 'true'
            }));
        } else if (NUMERIC_FIELDS.includes(field)) {
            // Parse numeric fields to integer
            newValue = value === '' ? null : parseInt(value, 10);
            setFormData(prev => ({
                ...prev,
                [field]: newValue
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [field]: newValue
            }));
        }
    }, []);

    const handleFileProcessed = useCallback((field, metadata) => {
        setFormData(prev => ({
            ...prev,
            [field]: {
                hash: metadata.hash,
                extension: metadata.extension,
            },
        }));
    }, []);

    const handleFileSelected = useCallback((field, metadata) => {
        setFiles(prev => ({
            ...prev,
            [field]: metadata.file, // Store the file in the files state for later upload
        }));
    }, []);

    const validateForm = useCallback(() => {
        if (!formData.modelDescription || !formData.product || !formData.difference || formData.creator === null) {
            createFeedback('warn', i18next.t('complete-the-form-data'));
            scrollToFeedback();
            return false;
        }

        if (formData.creator !== true && formData.creator !== false) {
            createFeedback('warn', i18next.t('creator-required'));
            scrollToFeedback();
            return false;
        }

        return true;
    }, [formData, createFeedback]);

    // Register company innovate
    const registerInnovateMutation = useMutation({
        mutationFn: () => registerCompanyInnovate(
            formData.formalizedCountry,
            formData.modelDescription,
            formData.product,
            formData.difference,
            formData.creator,
            formData.incomePrevious,
            formData.incomeLast,
            formData.level,
            formData.cagr,
            formData.cac,
            formData.ltv,
            formData.churnRate,
            formData.clients,
            formData.time,
            formData.profit,
            formData.commercialRegistry,
            formData.representativeID,
            formData.patent,
            formData.teamInfo,
            formData.founder,
            formData.fullTeam,
            formData.workTeam,
            session,
            i18next.language
        ),
        onMutate: removeFeedback,
        onSuccess: async (data) => {
            if (data.signaturePolicies) {
                try {
                    await uploadFiles(data.signaturePolicies, files);
                    setShowResults(true);
                    createFeedback('success', data.messages);
                } catch (uploadError) {
                    createFeedback('error', uploadError.messages?.join(', ') || uploadError.message);
                }
            } else {
                createFeedback(data.context, data.messages);
            }
            scrollToFeedback();
        },
        onError: (error) => {
            createFeedback(error.context, error.messages);
            scrollToFeedback();
        },
    });

    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();
        if (validateForm()) {
            registerInnovateMutation.mutate();
        }
    }, [validateForm, registerInnovateMutation]);

    if (isLoadingValidation) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <p className="text-gray-700">{i18next.t('loading')}</p>
            </div>
        );
    }

    // Display that the company data has been saved
    // ToDo: Volver un componente
    if (showResults) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="px-6 py-8 sm:p-10 flex flex-col items-center gap-4">
                    <Title size="2xl" className="text-center text-orange-600">
                        {i18next.t('Congratulations!', '¡Felicitaciones!')}
                    </Title>
                    <p className="text-lg text-gray-700 text-center leading-relaxed max-w-prose">
                        {i18next.t(
                            'You can now participate in various innovation challenges available on our platform. Click below to explore and contribute your solutions!',
                            '¡Ahora puedes participar en los diferentes retos de innovación disponibles en nuestra plataforma! Haz clic a continuación para explorarlos y aportar tus soluciones.'
                        )}
                    </p>
                    <Button
                        color="orange"
                        onClick={() => navigate('/competitions/innovation')}
                        icon={faPlay}
                        className="text-white"
                    >
                        {i18next.t('View Innovation Challenges', 'Ver Retos de Innovación')}
                    </Button>
                </div>
            </div>
        );
    }


    return (
        <div className="w-full flex flex-col gap-5">
            <NavbarTemplate>
                <div className="bg-white border-b-2 border-orange-200 overflow-hidden flex items-center">
                    <div className="px-4 py-5 sm:p-6 flex w-full">
                        <div className="flex w-8/12 items-center">
                            <Title size="xl">{i18next.t('company-register-innovate')}</Title>
                        </div>
                        <div className="flex w-4/12 justify-end items-center px-4 rounded-lg">
                            <div className="flex items-center gap-x-3" title={i18next.t('go-back')}>
                                <Button onClick={handleBack} color="gray" icon={faArrowLeftLong}>
                                    {i18next.t('go-back')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <Container>
                    <div ref={feedbackRef}>
                        {feedbackElement}
                    </div>
                    <form className="w-full px-5 py-7 flex flex-col gap-7 items-center" onSubmit={handleSubmit}>
                        <div className="w-full space-y-6">
                            {/* 1. INFORMACIÓN DE LA STARTUP */}
                            <section className="p-6 bg-white shadow-md rounded-lg border border-smorange">
                                <Title size="lg" className="mb-6">Información de la Empresa</Title>
                                <div className="grid grid-cols-1">
                                    <Input
                                        type="textarea"
                                        value={formData.modelDescription}
                                        onType={handleChange('modelDescription')}
                                        label="Describa brevemente el negocio de su startup, haga especial énfasis en la propuesta de valor, clientes, canales y fuentes de ingreso."
                                        labelColor="gray"
                                        required
                                    />
                                    <Input
                                        type="textarea"
                                        value={formData.product}
                                        onType={handleChange('product')}
                                        label="¿Cuál es el producto o servicio que su empresa ofrece al mercado?"
                                        labelColor="gray"
                                        required
                                    />
                                    <Input
                                        type="textarea"
                                        value={formData.difference}
                                        onType={handleChange('difference')}
                                        label="¿Qué los diferencia frente a otras empresas que ofrecen servicios similares?"
                                        labelColor="gray"
                                        labelSize='md'
                                        required
                                    />
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                                    <Select
                                        color="white"
                                        isLoading={isCountryListLoading}
                                        selected={formData.formalizedCountry}
                                        onSelect={handleChange('formalizedCountry')}
                                        options={countries || {}}
                                        placeholder="País de legalización"
                                    />
                                    <Select
                                        color="white"
                                        selected={formData.creator !== null ? formData.creator.toString() : null}
                                        onSelect={(value) => handleChange('creator')(value)}
                                        options={{ 
                                            null: { 
                                                'true': 'Sí', 
                                                'false': 'No' 
                                            } 
                                        }}
                                        order={['true', 'false']}
                                        placeholder="¿Son desarrolladores de su propia tecnología?"
                                        required
                                    />
                                    <Input
                                        value={formData.level}
                                        onType={handleChange('level')}
                                        label="¿En qué nivel de desarrollo se encuentra la solución/tecnología con la que desea participar en el Programa? (TRL)"
                                        labelColor="gray"
                                    />
                                    <div className="mt-0 lg:mt-5">
                                        <Input
                                            type="number"
                                            value={formData.clients !== null ? formData.clients : ''}
                                            onType={handleChange('clients')}
                                            label="¿Con cuántos clientes cuentan actualmente?"
                                            labelColor="gray"
                                        />
                                    </div>
                                </div>
                                
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 border border-smorange">
                                    <FileUploader 
                                        label="Cámara de comercio/Registro Mercantil/Equivalente" 
                                        onFileProcessed={(metadata) => {
                                            handleFileProcessed('commercialRegistry', metadata);
                                            handleFileSelected('commercialRegistry', metadata);
                                        }} 
                                        isLoading={registerInnovateMutation.isLoading} 
                                    />
                                    <FileUploader 
                                        label="ID del representante legal"
                                        onFileProcessed={(metadata) => {
                                            handleFileProcessed('representativeID', metadata);
                                            handleFileSelected('representativeID', metadata);
                                        }} 
                                        isLoading={registerInnovateMutation.isLoading} 
                                    />
                                </div>
                            </section>

                            {/* 2. MÉTRICAS Y FINANZAS */}
                            <section className="p-6 bg-white shadow-md rounded-lg border border-smorange">
                                <Title size="lg" className="mb-6">Métricas y Finanzas</Title>
                                <div className="p-5 rounded bg-white border shadow-sm shadow-smorange">
                                    <Title size="md">Indique de cuánto fueron sus ventas netas en dólares americanos en los siguientes años:</Title>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                                        <Input
                                            type="number"
                                            value={formData.incomePrevious !== null ? formData.incomePrevious : ''}
                                            onType={handleChange('incomePrevious')}
                                            label="2023"
                                            labelColor="gray"
                                        />
                                        <Input
                                            type="number"
                                            value={formData.incomeLast !== null ? formData.incomeLast : ''}
                                            onType={handleChange('incomeLast')}
                                            label="2024"
                                            labelColor="gray"
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                                        <Input
                                            type="text"
                                            value={formData.time}
                                            onType={handleChange('time')}
                                            label="¿La operación que tienen actualmente les permite sostenerse por cuánto tiempo?"
                                            labelColor="gray"
                                            className="mt-4"
                                        />
                                        <Input
                                            type="text"
                                            value={formData.profit}
                                            onType={handleChange('profit')}
                                            label="¿Cuál es el margen de rentabilidad de su negocio?"
                                            labelColor="gray"
                                            className="mt-4"
                                        />
                                    </div>
                                    <Input
                                        type="number"
                                        value={formData.cagr !== null ? formData.cagr : ''}
                                        onType={handleChange('cagr')}
                                        label="¿Cuánto fue su CAGR en el último año? (porcentaje)"
                                        labelColor="gray"
                                        className="mt-4"
                                    />
                                </div>
                                <div className="shadow-sm shadow-smorange p-5 mt-6 rounded bg-white">
                                    <Title size="lg">Describa las métricas:</Title>
                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2">
                                        <Input
                                            value={formData.cac}
                                            onType={handleChange('cac')}
                                            label="CAC (customer acquisition cost)"
                                            labelColor="gray"
                                        />
                                        <Input
                                            value={formData.ltv}
                                            onType={handleChange('ltv')}
                                            label="LTV (Lifetime Value)"
                                            labelColor="gray"
                                        />
                                        <Input
                                            value={formData.churnRate}
                                            onType={handleChange('churnRate')}
                                            label="Churn Rate"
                                            labelColor="gray"
                                        />
                                    </div>
                                </div>
                            </section>

                            {/* 3. INFORMACIÓN DEL EQUIPO */}
                            <section className="p-6 bg-white shadow-md rounded-lg border border-smorange">
                                <Title size="lg" className="mb-6">Información del Equipo</Title>
                                <Input
                                    type="textarea"
                                    value={formData.founder}
                                    onType={handleChange('founder')}
                                    label="Bríndanos una breve información de cada uno de los fundadores (estudios, rol, dedicación, información relevante) Máximo 1000 caracteres"
                                    labelColor="gray"
                                />
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                                    <Input
                                        type="number"
                                        value={formData.fullTeam !== null ? formData.fullTeam : ''}
                                        onType={handleChange('fullTeam')}
                                        label="¿Cuántas personas constituyen su equipo de trabajo?"
                                        labelColor="gray"
                                    />
                                    <Input
                                        type="number"
                                        value={formData.workTeam !== null ? formData.workTeam : ''}
                                        onType={handleChange('workTeam')}
                                        label="¿Cuántas personas de ese equipo harán parte del equipo que trabajará en la solución del reto?"
                                        labelColor="gray"
                                    />
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                                    <FileUploader 
                                        label="Suba un archivo Excel con la información del equipo (nombre, cargo, tiempo vinculado, enlace LinkedIn)"
                                        onFileProcessed={(metadata) => {
                                            handleFileProcessed('teamInfo', metadata);
                                            handleFileSelected('teamInfo', metadata);
                                        }} 
                                        isLoading={registerInnovateMutation.isLoading}
                                    />
                                    <FileUploader 
                                        label="¿Su empresa cuenta con patentes, modelo de utilidad, diseños industriales y/o esquemas de trazado de circuitos integrados? En caso de que sí, adjunte copia del documento de propiedad."
                                        onFileProcessed={(metadata) => {
                                            handleFileProcessed('patent', metadata);
                                            handleFileSelected('patent', metadata);
                                        }} 
                                        isLoading={registerInnovateMutation.isLoading}
                                    />
                                </div>
                            </section>
                        </div>

                        <Button 
                            isLoading={registerInnovateMutation.isLoading} 
                            submit
                            color="orange" 
                            icon={faBuilding}
                        >
                            {i18next.t('save')}
                        </Button>
                    </form>
                </Container>
            </NavbarTemplate>
        </div>
    );
}

export default CompanyRegisterInnovate;