/**
 * This utility provides functions for securely generating random values
 * using the native window.crypto.getRandomValues API.
 */

/**
 * Generates a random integer in the range [0, max).
 *
 * @param {number} max - The upper bound (exclusive).
 * @returns {number} A random integer between 0 and (max - 1).
 */
export function getRandomInt(max) {
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return array[0] % max;
}

/**
 * Selects a random item from a non-empty array.
 *
 * @template T
 * @param {T[]} items - The array from which to select a random item.
 * @returns {T} A random element from the array.
 * @throws {Error} If the provided array is empty or invalid.
 */
export function getRandomItem(items) {
  if (!Array.isArray(items) || items.length === 0) {
    throw new Error('getRandomItem: The array is empty or not valid.');
  }

  const randomIndex = getRandomInt(items.length);
  return items[randomIndex];
}
