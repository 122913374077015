/**
 * Uploads files to the endpoints defined by the signature policies.
 *
 * This function iterates over the provided signature policies and, for each field,
 * checks if there is a corresponding file. If both a file and a policy exist, it creates
 * a FormData object, appends the necessary policy fields and the file itself, then performs
 * a POST request to the given URL.
 *
 * @async
 * @function uploadFiles
 * @param {Object} signaturePolicies - An object mapping field names to their respective upload policies.
 * Each policy should include:
 *   - url {string} - The endpoint to which the file will be uploaded.
 *   - fields {Object} - An object containing key/value pairs to be appended to the FormData.
 * @param {Object} files - An object mapping field names to the File objects to be uploaded.
 * @returns {Promise<void>} Resolves when all files have been successfully uploaded.
 * @throws {Error} Throws an error with details if any file upload fails.
 */
export default async function uploadFiles(signaturePolicies, files) {
  const uploadPromises = Object.keys(signaturePolicies).map(async (field) => {
    const policy = signaturePolicies[field];
    const file = files[field];
    if (file && policy) {
      const uploadFormData = new FormData();

      // Append each policy field to the form data.
      Object.keys(policy.fields).forEach((key) => {
        uploadFormData.append(key, policy.fields[key]);
      });

      // Append the file to be uploaded.
      uploadFormData.append('file', file);

      const response = await fetch(policy.url, {
        method: 'POST',
        body: uploadFormData,
      });

      // If the response is not OK and status is not 201 (Created), throw an error.
      if (!response.ok && response.status !== 201) {
        const errorDetails = await response.text();
        throw new Error(`Error uploading ${field}: ${errorDetails}`);
      }
    }
  });

  await Promise.all(uploadPromises);
}