import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import i18next from 'i18next';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import router from './routes/router';

import { DomainSettingsProvider } from './contexts/DomainSettingsContext';
import SessionProvider from './contexts/SessionProvider';

import enLanguage from './languages/en_US.json';
import esLanguage from './languages/es_ES.json';


import 'react-loading-skeleton/dist/skeleton.css';
import { ModalProvider } from './contexts/ModalProvider';


// Init New Relic
const mode = process.env.NODE_ENV === 'production' ? 'prod' : 'dev';
const newrelic = document.createElement('script');
newrelic.src = `${process.env.PUBLIC_URL}/newrelic.${mode}.js`;
newrelic.type = 'text/javascript';
document.head.appendChild(newrelic);

// Config translations
i18next.init({
    lng: localStorage.getItem('language') || process.env.REACT_APP_DEFAULT_LANGUAGE,
    resources: {
        en_US: { translation: enLanguage },
        es_ES: { translation: esLanguage },
    },
});

// Cache controller settings
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
        mutations: {
            retry: false,
        },
    },
});
// dom
ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <SessionProvider>
                <DomainSettingsProvider>
                    <ModalProvider>
                        <ReactQueryDevtools />
                        <RouterProvider router={router} />
                    </ModalProvider>
                </DomainSettingsProvider>
            </SessionProvider>
        </QueryClientProvider>
    </React.StrictMode>,
);
